import { FunctionComponent, createContext, h } from 'preact';
import { Route, Router, RouterOnChangeArgs } from 'preact-router';
import { MutableRef, useCallback, useRef, useState } from 'preact/hooks';
import Helmet from 'preact-helmet';

import Header from 'components/Header/Header';
import Kezdooldal from 'routes/Kezdooldal/Kezdooldal';
import Turak from 'routes/Turak/Turak';
import Termekek from 'routes/Termekek/Termekek';
import Kapcsolat from 'routes/Kapcsolat/Kapcsolat';

import { services } from 'services/services';

import style from './app.scss';
import Footer from 'components/Footer/Footer';
import { Constant } from 'components/App/Constant';
import { FloatingMenuButton } from 'components/FloatingMenu/FloatingMenu';
import Informaciok from 'routes/Informaciok/Informaciok';

export const Services = createContext(services);

const App = () => {
  const [state, setState] = useState({
    isLandingPage: false,
    isTop: false,
    floatingMenuOpened: '' as '' | 'top' | 'bottom',
    termekekMenuOpened: false,
  });

  const closeFloatingMenu = useCallback(() => {
    setState((p) => ({ ...p, floatingMenuOpened: '' }));
  }, []);
  const openFloatingMenuTop = useCallback(() => {
    setState((p) => ({ ...p, floatingMenuOpened: 'top' }));
  }, []);
  const openFloatingMenuBottom = useCallback(() => {
    setState((p) => ({ ...p, floatingMenuOpened: 'bottom' }));
  }, []);

  const openTermekekMenu = useCallback(() => {
    setState((p) => ({ ...p, termekekMenuOpened: true }));
  }, []);

  const closeTermekekMenu = useCallback(() => {
    setState((p) => ({ ...p, termekekMenuOpened: false }));
  }, []);

  const appContainerRef = useRef(null);
  services.appContainerRef = appContainerRef;

  const onRouteChange = useCallback((e: RouterOnChangeArgs) => {
    setState((p) => ({
      ...p,
      isLandingPage: e.url === '/' || e.url.startsWith('/kezdooldal'),
      isTop: (appContainerRef.current?.scrollTop || 0) === 0,
    }));
    if (appContainerRef.current) {
      appContainerRef.current.scrollTop = 0;
    }
  }, []);

  const onScroll = useCallback((e) => {
    setState((p) => {
      if (p.isTop !== (appContainerRef.current?.scrollTop === 0) || p.floatingMenuOpened !== '') {
        return { ...p, isTop: appContainerRef.current.scrollTop === 0, floatingMenuOpened: '' };
      }
      return p;
    });
  }, []);

  const onBackdropMouseDown = useCallback(
    (e) => {
      closeFloatingMenu();
      closeTermekekMenu();
    },
    [closeFloatingMenu, closeTermekekMenu]
  );

  const onScrollUpClicked = useCallback(() => appContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' }), []);
  return (
    <Services.Provider value={services}>
      <div id={style.app} ref={appContainerRef} onScroll={onScroll}>
        <Helmet
          defaultTitle={`${Constant.title.full} - Paks`}
          titleTemplate={`%s | ${Constant.title.short} - Paks`}
          meta={[
            { name: 'description', content: `A ${Constant.title.full} weboldala. Élelmiszer és vegyiáru kereskedelem és szállítás.` },
            {
              name: 'keywords',
              content:
                'Kajári Ker. Bt., Kajári, Kajari, Paks, Tolna, Tolna megye, Élelmiszer, Vegyiáru, Kereskedelem, Nagykereskedelem, Kereskedés, Beszállító, Szállítás, Étterem, Közintézmény, Intézmény, Étkeztetés, kajari.hu',
            },
            { name: 'robots', content: 'index, follow' },
            // { name: 'robots', content: 'none' },
          ]}
          link={[{ rel: 'canonical', href: 'https://www.kajari.hu' }]}
        />
        <Header
          isLandingPage={state.isLandingPage}
          isTop={state.isTop}
          floatingMenuOpened={state.floatingMenuOpened === 'top'}
          floatingMenuOpen={openFloatingMenuTop}
          floatingMenuClose={closeFloatingMenu}
        />
        <main id={style.main}>
          <Router onChange={onRouteChange}>
            <Route path="/" component={Kezdooldal} />
            <Route path="/kapcsolat" component={Kapcsolat} />
            <Route path="/termekek/:termekcsoport?" component={Termekek} />
            <Route path="/turak" component={Turak} />
            <Route path="/informaciok" component={Informaciok} />
          </Router>
        </main>
        <Footer />
        <FloatingMenuButton
          menuOpened={state.floatingMenuOpened === 'bottom'}
          openMenu={openFloatingMenuBottom}
          closeMenu={closeFloatingMenu}
          termekekMenuOpened={state.termekekMenuOpened}
          openTermekekMenu={openTermekekMenu}
          closeTermekekMenu={closeTermekekMenu}
          isTop={state.isTop}
          scrollUp={onScrollUpClicked}
        />
        <ScrollUp onClick={onScrollUpClicked} visible={!state.isTop} />
        {(state.floatingMenuOpened || state.termekekMenuOpened) && <div class={style.backdrop} onMouseDown={onBackdropMouseDown} />}
      </div>
    </Services.Provider>
  );
};

export default App;

const ScrollUp: FunctionComponent<{ onClick: () => void; visible: boolean }> = (props) => {
  const onClick = useCallback(() => props.onClick?.apply(undefined), []);

  return (
    <div class={`${style['scroll-up']} ${!props.visible ? 'hide' : null}`} onClick={onClick}>
      <img src="assets/icon/arrow-up-bold_white.svg" />
    </div>
  );
};
