import { Fragment, h } from 'preact';
import Helmet from 'preact-helmet';
import Button from 'components/Button/Button';
import { Link } from 'preact-router';
import Image from 'components/Image/Image';
import style from './Informaciok.scss';
import { useContext, useEffect, useLayoutEffect } from 'preact/hooks';
import { Services } from 'components/App/App';
import { Constant } from 'components/App/Constant';

const Informaciok = () => {
  const services = useContext(Services);
  const hash = location.hash;

  useLayoutEffect(() => {
    let scrollTop = 0;
    if (location.hash) {
      scrollTop = (document.querySelector(hash) as any).offsetTop - 100;
    }
    if (services.appContainerRef.current && scrollTop) {
      services.appContainerRef.current.scrollTop = scrollTop;
    }
  }, [services.appContainerRef, hash]);

  return (
    <Fragment>
      <Helmet
        title="Kapcsolat"
        meta={[
          {
            name: 'description',
            content: `A ${Constant.title.full} weboldala. Élelmiszer és vegyiáru kereskedelem és szállítás. - Általános információk, adatvédelem.`,
          },
        ]}
      />
      <div class="page">
        <div class={`page__header ${style.page__header}`}>
          <h1>Információk</h1>
        </div>
        <div class={'page__content'}>
          <div class="page__content__section">
            <div class="page__content__section__layout">
              <div class="page__content__section__layout__block_1 ai-start">
                <h2 id="altalanos-informaciok">Általános információk</h2>
                <p>A oldalon szereplő árak, információk és képek tájékoztató jellegűek, nem minősülnek ajánlattételnek.</p>
                <p>A oldalon szereplő árak forintban és nettóban értetendőek.</p>
                <p>A legjobb szándékunk ellenére is előfordulhatnak elírások, esetleges hibák, ezért elnézésüket kérjük.</p>
              </div>
            </div>
          </div>
          <div class="page__content__section">
            <div class="page__content__section__layout">
              <div class="page__content__section__layout__block_1 ai-start">
                <h2 id="adatvedelem">Adatvédelem</h2>
                <p>
                  Az oldal nem használ nyomkövetéshez kapcsolódó technológiákat (pl.: sütik), és nem tárol semmilyen a felhasználókhoz
                  köthető személyes adatot.
                </p>
              </div>
            </div>
          </div>
          <div class="page__content__section">
            <div class="page__content__section__layout">
              <div class="page__content__section__layout__block_1 ai-start">
                <h2 id="uzemelteto">Üzemeltetés</h2>
                <p>E-mail: selenops.hu[kukac]gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Informaciok;
