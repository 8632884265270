import { ClassAttributes, Fragment, FunctionComponent, h } from 'preact';
import { useCallback } from 'preact/hooks';
import style from './FloatingMenu.scss';
import MenuItem from 'components/Header/MenuItem';
import TermekekCsoportokFloatingMenu from 'routes/Termekek/TermekekcsoportokFloatingMenu';
import { Link } from 'preact-router';

const FloatingMenu: FunctionComponent<{ onAfterClick: () => void; bottom?: boolean } & ClassAttributes<any>> = (props) => {
  return (
    <div class={`${style.floating_menu} ${props.bottom ? style['floating_menu_button--bottom'] : null}`}>
      <MenuItem
        title="Kezdőoldal"
        href="/"
        onAfterClick={props.onAfterClick}
        class={style.menu_item}
        activeClass={style['menu_item--active']}
      />
      <MenuItem
        title="Kapcsolat"
        href="/kapcsolat"
        onAfterClick={props.onAfterClick}
        class={style.menu_item}
        activeClass={style['menu_item--active']}
      />
      <MenuItem
        title="Túrák"
        href="/turak"
        onAfterClick={props.onAfterClick}
        class={style.menu_item}
        activeClass={style['menu_item--active']}
      />
      <MenuItem
        title="Termékek"
        href="/termekek"
        onAfterClick={props.onAfterClick}
        class={style.menu_item}
        activeClass={style['menu_item--active']}
      />
      <MenuItem
        title="Aktuális ajánlataink"
        href="/termekek/aktualis-ajanlataink"
        onAfterClick={props.onAfterClick}
        class={style.menu_item}
        activeClass={style['menu_item--active']}
      />
    </div>
  );
};

const FloatingMenuButton = (props: {
  menuOpened: boolean;
  openMenu: () => void;
  closeMenu: () => void;
  termekekMenuOpened: boolean;
  openTermekekMenu: () => void;
  closeTermekekMenu: () => void;
  isTop: boolean;
  scrollUp: () => void;
}) => {
  const anyMenuOpened = props.menuOpened || props.termekekMenuOpened;

  const close = useCallback(() => {
    if (props.menuOpened) {
      props.closeMenu();
    }
    if (props.termekekMenuOpened) {
      props.closeTermekekMenu();
    }
  }, [props]);

  const toggleMenu = useCallback(() => {
    if (props.termekekMenuOpened) {
      props.closeTermekekMenu();
    }
    if (props.menuOpened) {
      props.closeMenu();
    } else {
      props.openMenu();
    }
  }, [props]);

  const toggleTermekMenu = useCallback(() => {
    if (props.menuOpened) {
      props.closeMenu();
    }
    if (props.termekekMenuOpened) {
      props.closeTermekekMenu();
    } else {
      props.openTermekekMenu();
    }
  }, [props]);

  return (
    <Fragment>
      <div class={style.floating_menu_button}>
        <div class="fx-c fx-pi-center-center" onClick={toggleMenu}>
          <img src="/assets/icon/menu_white.svg" style="width: 32px; height:32px" />
          <span class="fs-smaller-2">Menü</span>
        </div>
        <div class="fx-c fx-pi-center-center" onClick={toggleTermekMenu}>
          <img src={'/assets/icon/items_white.svg'} style="width: 32px; height:32px" />
          <span class="fs-smaller-2">Termékek</span>
        </div>
        <Link href="/termekek/aktualis-ajanlataink" onClick={close}>
          <div class="fx-c fx-pi-center-center">
            <img src={'/assets/icon/sale_white.svg'} style="width: 32px; height:32px" />
            <span class="fs-smaller-2">Ajánlataink</span>
          </div>
        </Link>
        {anyMenuOpened && (
          <div class={`fx-c fx-pi-center-center ${anyMenuOpened ? null : 'opaque'}`} onClick={close}>
            <img src={'/assets/icon/close_white.svg'} style="width: 32px; height:32px" />
          </div>
        )}
        {!anyMenuOpened && (
          <div class={`fx-c fx-pi-center-center ${!props.isTop ? null : 'opaque'}`} onClick={props.scrollUp}>
            <img src={'/assets/icon/arrow-up-bold_white.svg'} style="width: 32px; height:32px" />
          </div>
        )}
      </div>
      {props.menuOpened && <FloatingMenu onAfterClick={props.closeMenu} bottom={true} />}
      {props.termekekMenuOpened && <TermekekCsoportokFloatingMenu bottom={true} onAfterClick={props.closeTermekekMenu} />}
    </Fragment>
  );
};

export { FloatingMenu, FloatingMenuButton };
