import { MutableRef, useEffect, useRef, useState } from 'preact/hooks';

export interface UseIntersectionObserverOptions {
  root?: Element | Document | null;
  rootMargin?: string;
  threshold?: number | number[];
  once?: boolean;
}

export const useIntersectionObserver = (
  options?: UseIntersectionObserverOptions
): [MutableRef<any>, { visible: boolean; onceVisible: boolean; class: string }] => {
  const [state, setState] = useState({ visible: false, onceVisible: false });
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      const obs = new IntersectionObserver((entries) => {
        setState((p) => ({
          visible: entries[0].isIntersecting === true,
          onceVisible: p.onceVisible || entries[0].isIntersecting === true,
        }));
      }, options);
      obs.observe(ref.current);
      return () => obs.disconnect();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return [ref, { ...state, class: [state.visible ? 'visible' : 'non-visible', state.onceVisible ? 'once-visible' : ''].join(' ') }];
};
