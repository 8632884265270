import { h } from 'preact';
import style from './Button.scss';

const Button = (props: { label: string; href?: string; inverse?: boolean; onClick?: () => void }) => {
  if (props.href) {
    return (
      <a href={props.href}>
        <div class={`${style.button} ${props.inverse ? style['button--inverse'] : ''}`}>{props.label}</div>
      </a>
    );
  }
  return (
    <div class={`${style.button} ${props.inverse ? style['button--inverse'] : ''}`} onClick={props.onClick}>
      {props.label}
    </div>
  );
};

export default Button;
