import { Fragment, h } from 'preact';
import Helmet from 'preact-helmet';
import style from './kapcsolat.scss';
import Button from 'components/Button/Button';
import { Link } from 'preact-router';
import Image from 'components/Image/Image';
import { Constant } from 'components/App/Constant';

const Kapcsolat = () => {
  return (
    <Fragment>
      <Helmet
        title="Kapcsolat"
        meta={[
          {
            name: 'description',
            content: `A ${Constant.title.full} weboldala. Élelmiszer és vegyiáru kereskedelem és szállítás. - Kapcsolat, elérhetőség, telephely.`,
          },
        ]}
      />
      <div class="page">
        <div class={`page__header ${style.page__header}`}>
          <h1 class="flyin-left">Kapcsolat</h1>
        </div>
        <div class={'page__content'}>
          <div class="page__content__section">
            <div class="page__content__section__layout">
              <div class="page__content__section__layout__block_2 jc-start ai-start">
                <h2>Kajári Kereskedelmi és Szolgáltató Bt.</h2>
                <table class="prop-table prop-table-brd">
                  <tr>
                    <td style="min-width: 130px">Számlázási cím:</td>
                    <td style="min-width: 260px">
                      <span class="no-wrap">{Constant.contact.billing_address_part1} </span>
                      <span class="no-wrap">{Constant.contact.billing_address_part2}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Adószám:</td>
                    <td>{Constant.data.adoszam}</td>
                  </tr>
                  <tr>
                    <td>E-mail:</td>
                    <td>
                      <Link href={`mailto:${Constant.contact.email}`}>{Constant.contact.email}</Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Tel.:</td>
                    <td>
                      <div class="fx-rw">
                        <Link href={`tel:${Constant.contact.phone_unformatted}`}>
                          <span class="no-wrap">{Constant.contact.phone}</span>
                        </Link>
                        <div class="fx-fill" style="min-width: 5px" />
                        <span class="italic no-wrap" style="font-weight: 400">
                          ({Constant.contact.phone_detail})
                        </span>
                      </div>
                    </td>
                  </tr>
                </table>
                <table class="prop-table prop-table-brd mrg-t-2">
                  <tr>
                    <td style="min-width: 130px; font-weight: 600">{Constant.contact.phone2_name}</td>
                    <td style="min-width: 240px; font-weight: 400">{Constant.contact.phone2_title}</td>
                  </tr>
                  <tr>
                    <td>Tel.:</td>
                    <td>
                      <div class="fx-rw">
                        <Link href={`tel:${Constant.contact.phone2_unformatted}`}>
                          <span class="no-wrap">{Constant.contact.phone2}</span>
                        </Link>
                        <div class="fx-fill" style="min-width: 5px" />
                        <span class="italic no-wrap" style="font-weight: 400">
                          ({Constant.contact.phone2_detail})
                        </span>
                      </div>
                    </td>
                  </tr>
                </table>
                <table class="prop-table prop-table-brd mrg-t-2">
                  <tr>
                    <td style="min-width: 130px; font-weight: 600">{Constant.contact.phone3_name}</td>
                    <td style="min-width: 240px; font-weight: 400">{Constant.contact.phone3_title}</td>
                  </tr>
                  <tr>
                    <td>Tel.:</td>
                    <td>
                      <Link href={`tel:${Constant.contact.phone3_unformatted}`}>
                        <span class="no-wrap">{Constant.contact.phone3}</span>
                      </Link>
                    </td>
                  </tr>
                </table>
                <div class="pad-t-2 as-center">
                  <Button label="Írjon nekünk!" href={`mailto:${Constant.contact.email}`} />
                </div>
              </div>
              <div class="page__content__section__layout__block_2">
                <Image src="assets/images/contact_1_w400.webp" responsive={true} lazy={true} alt={'Üzletkötő munkavégzés közben'} />
              </div>
            </div>
          </div>
          <div class={`page__content__section ${style['section-telephely']}`}>
            <div class="page__content__section__layout">
              <div class="page__content__section__layout__block_2">
                <Image
                  src="assets/images/telephely_3_w400.webp"
                  responsive={true}
                  lazy={true}
                  alt={`A ${Constant.title.short} paksi telephelye`}
                />
              </div>
              <div class="page__content__section__layout__block_2 jc-start ai-start">
                <h2>Telephely</h2>
                <table class="prop-table prop-table-brd">
                  <tr>
                    <td style="min-width: 130px">Cím:</td>
                    <td style="min-width: 240px">
                      <span class="no-wrap">{Constant.contact.warehouse_address_part_1} </span>
                      <span class="no-wrap">{Constant.contact.warehouse_address_part_2}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Tel.:</td>
                    <td>
                      <Link href={`tel:${Constant.contact.phone_unformatted}`}>{Constant.contact.phone}</Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Áruátvétel:</td>
                    <td>{Constant.contact.warehouse_address_detail}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="page__content__section__layout">
              <div class="page__content__section__layout__block_1">
                <iframe
                  alt={`A ${Constant.title.short} paksi telephelyének elhelyezkedése`}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1390024.1162524193!2d18.105482783777248!3d47.120810482711505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47425c3fe20be517%3A0x7f1a62f95466a9f9!2sPaks%2C%20Dank%C3%B3%20Pista%20u.%2025-b%2C%207030!5e0!3m2!1sen!2shu!4v1686101526957!5m2!1sen!2shu"
                  width="100%"
                  allow="fullscreen"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  class={style.gmap}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Kapcsolat;
