export const Constant = {
  title: {
    full: 'Kajári Kereskedelmi és Szolgáltató Bt.',
    short: 'Kajári Ker. Bt.',
    name: 'Kajári',
    name_suffix: 'Kereskedelmi és Szolgáltató Bt.',
    name_suffix_short: 'Ker. és Szolg. Bt.',
    slogen: 'Élelmiszer, vegyiáru',
  },
  contact: {
    billing_address_part1: '7030 Paks',
    billing_address_part2: 'Móra Ferenc utca 26.',
    warehouse_address_part_1: '7030 Paks',
    warehouse_address_part_2: 'Dankó Pista utca 25/b.',
    warehouse_address_detail: 'Hétfőtől péntekig 6:00-14:00',
    phone: '+3675 510-065',
    phone_unformatted: '+3675510065',
    phone_detail: 'Hétfőtől péntekig 5:30-14:30',
    phone2: '+3620 229-6899',
    phone2_unformatted: '+36202296899',
    phone2_detail: '24/7',
    phone2_name: 'Tóth Eduárd',
    phone2_title: 'Területi képviselő',
    phone3: '+3630 520-2382',
    phone3_unformatted: '+36305202382',
    phone3_detail: '',
    phone3_name: 'Sudár Zoltán',
    phone3_title: 'Területi képviselő',
    email: 'kajari@kajariker.t-online.hu',
  },
  data: {
    adoszam: '26462042-2-17',
  },
};
