import { Fragment, FunctionalComponent, h } from 'preact';
import style from './TermekekcsoportokFloatingMenu.scss';
import { Link } from 'preact-router';
import { ApiServiceInstance, TermekcsoportDTO } from 'services/api-service';
import { useState, useEffect } from 'preact/hooks';
import Loading from 'components/Loading/Loading';

const TermekekCsoportokFloatingMenu: FunctionalComponent<{ bottom?: boolean; onAfterClick?: () => void }> = (props) => {
  const [termekcsoportList, setTermekcsoportList] = useState([] as TermekcsoportDTO[]);

  useEffect(() => {
    async function fetch() {
      const api = ApiServiceInstance;
      const items = await api.getTermekcsoportList();
      setTermekcsoportList(items);
    }

    fetch();
  }, []);

  return (
    <div class={`${style.termekcsoportok_menu} ${props.bottom ? style['termekcsoportok_menu--bottom'] : null}`}>
      {termekcsoportList.length === 0 && <Loading />}
      {termekcsoportList.map((cs) => {
        return (
          <Link
            activeClassName={'active'}
            key={cs.id}
            href={`/termekek/${cs.urlPart}`}
            onClick={props.onAfterClick}
            class={`${style.termekcsoport}`}
          >
            <span class={`${cs.id === -1 || cs.id === 0 ? 'bold' : null} ${cs.id === -1 ? 'italic' : null}`}>{cs.nev}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default TermekekCsoportokFloatingMenu;
