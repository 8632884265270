import { createBreakpointObserverSignal } from 'services/breakpoint-observer';
import { MutableRef } from 'preact/hooks';

export const services = {
  breakpoint: createBreakpointObserverSignal(),
  appContainerRef: null as MutableRef<any> | null,
};

export const isPrerendering = (): boolean => {
  return !(typeof window !== 'undefined');
};
