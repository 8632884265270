import { Fragment, FunctionComponent, h } from 'preact';
import style from './Termekek.scss';
import Image from 'components/Image/Image';

function addFt(s: string): string {
  if (!s) {
    return s;
  }
  const i = s.indexOf('/');
  if (i === -1) {
    return `${s} Ft`;
  }
  return `${s.substring(0, i)} Ft/${s.substring(i + 1)}`;
}

const Termek: FunctionComponent<{ name: string; imgSrc: string; rowLayout: boolean; ar?: string; colImgMaxHeight?: number }> = (props) => {
  const { name, imgSrc, ar } = props;
  let { colImgMaxHeight } = props;
  if (!colImgMaxHeight) {
    colImgMaxHeight = 200;
  }
  return (
    <div
      class={`${props.rowLayout ? 'fx-r gap-r-3' : 'fx-c gap-c-0_5'} jc-start ai-center  ${style.item} ${props.ar ? style.akcios : null}`}
      style="position: relative"
    >
      <div
        class={`${style['image-container']} fx-r fx-pi-center-center ${imgSrc ? 'pad-0_25' : 'pad-1'} ${imgSrc ? null : 'opaque'} ${
          imgSrc ? null : 'brd'
        }`}
        style={`height: ${
          props.rowLayout ? '100px' : `${colImgMaxHeight}px`
        }; aspect-ratio: 1 / 1; overflow-y: hidden; box-sizing: border-box; max-width: 100%`}
      >
        <Image
          src={imgSrc || '/assets/logo/logo3_blue.webp'}
          shadow={false}
          width={props.rowLayout ? '160px' : undefined}
          lazy={true}
          useBorderRadius={false}
          useAspectRatio={false}
          alt={`A(z) ${name} termék képe`}
        />
      </div>
      <div class="fx-c fx-pi-center-center" style="min-height: 3.6em">
        <span class="bold fs-larger">{name}</span>
      </div>
      {ar && props.rowLayout && (
        <Fragment>
          <div class="fx-fill" />
          <div class="fx-c fx-pi-center-center" style="min-height: 3.6em">
            <span class="bold fs-larger-3">{addFt(ar)}</span>
          </div>
        </Fragment>
      )}
      {ar && !props.rowLayout && (
        <div class={style.badge}>
          <span class="bold fs-larger-3">{addFt(ar)}</span>
        </div>
      )}
    </div>
  );
};

export default Termek;
