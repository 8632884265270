import { FunctionComponent, h } from 'preact';
import { Link } from 'preact-router/match';
import { MutableRef, useCallback } from 'preact/hooks';
import style from './MenuItem.scss';

const MenuItem: FunctionComponent<{
  title: string;
  href: string;
  onAfterClick?: () => void;
  onMouseEnter?: () => void;
  ref?: MutableRef<any>;
  active?: boolean;
  class?: string;
  activeClass?: string;
}> = (props) => {
  const onAfterClick = props.onAfterClick;
  const onClick = useCallback(() => {
    onAfterClick && onAfterClick();
  }, [onAfterClick]);
  return (
    <Link
      activeClassName={'active'}
      href={props.href}
      ref={props.ref}
      onClick={onClick}
      class={`fx-r fx-pi-center-center ${style.menu_item} ${props.active ? 'active' : ''}  ${props.class}`}
    >
      <div class={` ${style.menu_item}`} onMouseEnter={props.onMouseEnter}>
        {props.title}
      </div>
    </Link>
  );
};

export default MenuItem;
