import { h } from 'preact';
import style from './Footer.scss';
import { Link } from 'preact-router';
import { Constant } from 'components/App/Constant';

const Footer = () => {
  return (
    <div class={style.footer}>
      <div class={style.footer__layout}>
        <div style="flex: 0 0 auto">
          <table class="prop-table letter-spacing-0_025" style="font-weight: 400;">
            <tr>
              <td colSpan={2} class="bold italic letter-spacing-0_05">
                {Constant.title.full}
              </td>
            </tr>
            <tr>
              <td>Számlázási cím:</td>
              <td style="font-weight: 400">
                <span class="no-wrap">{Constant.contact.billing_address_part1} </span>
                <span class="no-wrap">{Constant.contact.billing_address_part2}</span>
              </td>
            </tr>
            <tr>
              <td>Adószám:</td>
              <td style="font-weight: 400">{Constant.data.adoszam}</td>
            </tr>
            <tr>
              <td>Telephely:</td>
              <td style="font-weight: 400">
                {' '}
                <span class="no-wrap">{Constant.contact.warehouse_address_part_1} </span>
                <span class="no-wrap">{Constant.contact.warehouse_address_part_2}</span>
              </td>
            </tr>
            <tr>
              <td>Tel.:</td>
              <td style="font-weight: 400">
                <Link href={`tel:${Constant.contact.phone_unformatted}`}>{Constant.contact.phone}</Link>
                <span class="italic no-wrap"> ({Constant.contact.phone_detail})</span>
              </td>
            </tr>
            <tr>
              <td />
              <td style="font-weight: 400">
                <Link href={`tel:${Constant.contact.phone2_unformatted}`}>{Constant.contact.phone2}</Link>
                <span class="italic no-wrap"> ({Constant.contact.phone2_detail})</span>
              </td>
            </tr>
            <tr>
              <td>E-mail:</td>
              <td style="font-weight: 400">
                <Link href={`mailto:${Constant.contact.email}}`}>
                  <span>{Constant.contact.email}</span>
                </Link>
              </td>
            </tr>
          </table>
        </div>
        <div style="flex: 1 1 100%" class="hide-lt-md" />
        <div style="flex: 0 0 auto" class="hide-lt-md">
          <table class="prop-table letter-spacing-0_025" style="font-weight: 400; min-width: 200px;">
            <tr>
              <td class="bold italic letter-spacing-0_05">Navigáció</td>
            </tr>
            <tr>
              <td>
                <Link href="/">
                  <span>Kezdőoldal</span>
                </Link>
              </td>
            </tr>
            <tr>
              <td>
                <Link href="/kapcsolat">
                  <span>Kapcsolat</span>
                </Link>
              </td>
            </tr>
            <tr>
              <td>
                <Link href="/turak">
                  <span>Túrák</span>
                </Link>
              </td>
            </tr>
            <tr>
              <td>
                <Link href="/termekek">
                  <span>Termékek</span>
                </Link>
              </td>
            </tr>
            <tr>
              <td>
                <Link href="/termekek/aktualis-ajanlataink">
                  <span>Aktuális ajánlataink</span>
                </Link>
              </td>
            </tr>
          </table>
        </div>
        <div class="fx-c" style="flex: 0 0 auto">
          <table class="prop-table letter-spacing-0_025" style="font-weight: 400; min-width: 200px">
            <tr>
              <td class="bold italic letter-spacing-0_05">Információk</td>
            </tr>
            <tr>
              <td>
                <Link href="/informaciok#altalanos-informaciok">
                  <span>Általános információk</span>
                </Link>
              </td>
            </tr>
            <tr>
              <td>
                <Link href="/informaciok#adatvedelem">
                  <span>Adatvédelem</span>
                </Link>
              </td>
            </tr>
            <tr>
              <td>
                <Link href="/informaciok#uzemelteto">
                  <span>Üzemeltetés</span>
                </Link>
              </td>
            </tr>
          </table>
          <div class="fx-fill" />
          <span class="letter-spacing-0_025 pad-t-1">
            {Constant.title.short} <span class="fs-larger">©</span> 2023
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
