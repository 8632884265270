import { FunctionComponent, h } from 'preact';
import style from './Image.scss';
import { useIntersectionObserver } from 'services/useIntersectionObserver';

const Image: FunctionComponent<{
  src: string;
  fadein?: boolean;
  shadow?: boolean;
  responsive?: boolean;
  lazy?: boolean;
  alt?: string;
  width?: string;
  height?: string;
  useBorderRadius?: boolean;
  useAspectRatio?: boolean;
}> = (props) => {
  const [ref, visible] = useIntersectionObserver({ once: true, rootMargin: '-100px' });

  const fadein = props.fadein ?? true;
  const shadow = props.shadow ?? true;

  if (props.src) {
    if (props.responsive) {
      const srcset = `${props.src} 400w, ${props.src.replace('w400', 'w600')} 600w, ${props.src.replace('w400', 'w960')} 960w`;
      const sizes = '(max-width: 959.9px) 96vw, 600px';

      return (
        <img
          alt={props.alt || ''}
          ref={ref}
          class={`${style.image} ${fadein ? style.fade_in : ''} ${shadow ? style.shadow : ''} ${visible.class} 
          ${props.useBorderRadius ?? true ? style['use-border-radius'] : null}
          ${props.useAspectRatio ?? true ? style['use-aspect-ratio'] : null}`}
          src={props.src.replace('w400', 'w600').replace('.webp', '.png')}
          loading={props.lazy ? 'lazy' : 'eager'}
          srcset={srcset}
          sizes={sizes}
          width={'100%'}
          height={props.height || 'auto'}
          style={props.width ? { width: `${props.width}` } : undefined}
        />
      );
    }
    return (
      <img
        alt={props.alt || ''}
        ref={ref}
        class={`${style.image} ${fadein ? style.fade_in : ''} ${shadow ? style.shadow : ''}  ${visible.class}`}
        src={props.src}
        loading={props.lazy ? 'lazy' : 'eager'}
        width={'100%'}
        height={'auto'}
        style={props.width ? { width: `${props.width}` } : undefined}
      />
    );
  }
  return (
    <div class={style['no-image']}>
      <span>Nincs kép</span>
    </div>
  );
};

export default Image;
