import { ClassAttributes, Fragment, FunctionComponent, h } from 'preact';
import { Link } from 'preact-router/match';
import { MutableRef, useCallback, useLayoutEffect, useRef, useState } from 'preact/hooks';
import style from './header.scss';
import { Constant } from 'components/App/Constant';
import MenuItem from 'components/Header/MenuItem';
import { FloatingMenu } from 'components/FloatingMenu/FloatingMenu';
import TermekekcsoportokFloatingMenu from 'routes/Termekek/TermekekcsoportokFloatingMenu';

const Header = (props: {
  isLandingPage: boolean;
  isTop: boolean;
  floatingMenuOpened: boolean;
  floatingMenuOpen: () => void;
  floatingMenuClose: () => void;
}) => {
  const [state, setState] = useState({ termekekMenuOpened: false });

  const termekekLinkRef = useRef(null);
  const termekekMenuRef = useRef(null);
  const setTermekekMenuOpened = useCallback(() => {
    setState((p) => ({ ...p, termekekMenuOpened: true }));
  }, []);
  const setTermekekMenuClosed = useCallback(() => {
    setState((p) => ({ ...p, termekekMenuOpened: false }));
  }, []);

  useLayoutEffect(() => {
    if (termekekLinkRef.current?.base && termekekMenuRef.current?.base) {
      const x = typeof window !== 'undefined' ? window.innerWidth : 0;
      termekekMenuRef.current.base.style.right = `${x - termekekLinkRef.current.base.offsetLeft - 140}px`;
      termekekMenuRef.current.base.style.top = `${termekekLinkRef.current.base.offsetTop + termekekLinkRef.current.base.clientHeight}px`;
    }
  });

  return (
    <Fragment>
      <header
        class={`${style.header} ${props.isLandingPage ? style['header--landing'] : ''} ${props.isTop ? style['header--top'] : ''}`}
        onMouseLeave={setTermekekMenuClosed}
      >
        <nav>
          <Link class={style.title} href="/">
            <img class={`${style.title__logo}`} src="/assets/logo/logo3_white.webp" height={60} width="auto" />
            {/* <img class={style.title__logo} src="/assets/logo/logo2-white.png" />
            <div class={style.title__title}>
              <span class="fs-larger">{Constant.title.name}</span>
              <span class="fs-smaller">{Constant.title.name_suffix}</span>
            </div> */}
          </Link>
          <div class="fx-fill" />
          <div
            class={'hide-gte-md relative h-100 pad-0_75 pointer'}
            onClick={props.floatingMenuOpened ? props.floatingMenuClose : props.floatingMenuOpen}
          >
            <img src={props.floatingMenuOpened ? '/assets/icon/close_white.svg' : '/assets/icon/menu_white.svg'} class="wh-100" />
          </div>
          <div class={`${style.menu} hide-lt-md`}>
            <MenuItem title="Kezdőoldal" href="/" />
            <MenuItem title="Kapcsolat" href="/kapcsolat" />
            <MenuItem title="Túrák" href="/turak" onMouseEnter={setTermekekMenuClosed} />
            <MenuItem
              title="Termékek"
              href="/termekek"
              onMouseEnter={setTermekekMenuOpened}
              ref={termekekLinkRef}
              active={state.termekekMenuOpened}
            />
            <MenuItem title="Aktuális ajánlataink" href="/termekek/aktualis-ajanlataink" onMouseEnter={setTermekekMenuClosed} />
          </div>
        </nav>
        {props.floatingMenuOpened && <FloatingMenu onAfterClick={props.floatingMenuClose} />}
        {state.termekekMenuOpened && <TermekekcsoportokFloatingMenu ref={termekekMenuRef} />}
      </header>
    </Fragment>
  );
};

export default Header;
