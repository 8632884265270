import { Fragment, h } from 'preact';
import Helmet from 'preact-helmet';
import style from './turak.scss';
import Image from 'components/Image/Image';
import Button from 'components/Button/Button';
import { Constant } from 'components/App/Constant';

const Turak = () => {
  return (
    <Fragment>
      <Helmet
        title="Túrák"
        meta={[
          {
            name: 'description',
            content: `A ${Constant.title.full} weboldala. Élelmiszer és vegyiáru kereskedelem és szállítás. - Heti rendszerességű túrák, szállítás az ország egész területére.`,
          },
        ]}
      />
      <div class="page">
        <div class={`page__header ${style.page__header}`}>
          <h1 class="flyin-left">Túrák</h1>
        </div>
        <div class={`page__content ${style.page__content}`}>
          <div class="page__content__section">
            <div class="page__content__section__layout">
              <div class="page__content__section__layout__block_2 jc-start ai-start">
                <h2>Heti rendszerességű túrák</h2>
                <p>
                  Heti rendszerességű túrajárataink paksi telephelyünkről indulnak hétfőtől péntekig, elsősorban Tolna vármegyével határos
                  megyék szinte összes településére.
                </p>
                <ul>
                  <li>Tolna</li>
                  <li>Baranya</li>
                  <li>Somogy</li>
                  <li>Fejér</li>
                  <li>Bács-Kiskun</li>
                </ul>
              </div>
              <div class="page__content__section__layout__block_2">
                <Image
                  src="assets/images/svg/hu_turak.svg"
                  lazy={true}
                  alt={`A ${Constant.title.short} heti rendszerességű túráinak magyarországi lefedettsége`}
                />
              </div>
            </div>
          </div>
          <div class="page__content__section">
            <div class="page__content__section__layout">
              <div class="page__content__section__layout__block_2 jc-start ai-start">
                <h2>Szállítás az ország egész területére</h2>
                <p>
                  Cégünk az Eurogasztro cégcsoport tagja így partnercégeinkkel az ország teljes területén biztosított a lefedettség. Kérjen
                  egyedi ajánlatot elérhetőségeink egyikén.
                </p>
                <div class="as-center">
                  <Button label="Kapcsolat" href="/kapcsolat" />
                </div>
              </div>
              <div class="page__content__section__layout__block_2">
                <Image
                  src="assets/images/flotta_1_w400.webp"
                  responsive={true}
                  lazy={true}
                  alt={`Gépjármű flotta a ${Constant.title.short} paksi telephelyén`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Turak;
