import { Fragment, h } from 'preact';
import style from './Kezdooldal.scss';
import Button from 'components/Button/Button';
import Image from 'components/Image/Image';
import { useState, useContext, useCallback } from 'preact/hooks';
import { Services } from 'components/App/App';
import { Constant } from 'components/App/Constant';

const Kezdooldal = () => {
  const services = useContext(Services);
  const [state, setState] = useState({ bemutatkozasFull: services.breakpoint.value.isMatch('gt_sm') });
  const setBemutatkozasFull = useCallback(() => {
    setState((p) => ({ ...p, bemutatkozasFull: true }));
  }, []);

  return (
    <div class={'page'}>
      <div class={`page__header ${style.page__header}`}>
        <div class={style.page__header__title}>
          <h1 class="flyin-left">Kajári</h1>
          <h2 class="flyin-left">Kereskedelmi és Szolgáltató Bt.</h2>
          <h3 class="flyin-right">Élelmiszer, vegyiáru</h3>
        </div>
      </div>
      <div class="page__content">
        <div class={`page__content__section ${style.section_bemutatkozas}`}>
          <div class="page__content__section__layout">
            <div class="page__content__section__layout__block_2">
              <h1 class="as-start">Bemutatkozás</h1>
              <p>
                Cégünk egy 1993-ban alapított családi vállalkozás. Eleinte csak konzervekkel foglalkoztunk mára viszont már a teljes
                füszértáru kínálat, a tejtermékek, a friss zöldség és gyümölcs, a mirelit termékek valamint a vegyiáru is megtalálható a
                palettán.
                {!state.bemutatkozasFull && (
                  <span class="mrg-0 italic underline pointer hide-gte-md" onClick={setBemutatkozasFull}>
                    <br />
                    Tovább olvasom
                  </span>
                )}
              </p>
              {state.bemutatkozasFull && (
                <Fragment>
                  <p>
                    Mostanra már több mint 600 elégedett ügyféllel dolgozunk együtt nap, mint nap. Fő profilunk a közétkeztető cégek,
                    éttermek, boltok, egyéb intézmények kiszolgálása.
                  </p>
                  <p>
                    Ha kérdése van keressen minket bizalommal a kapcsolat menüpont alatt található elérhetőségeink egyikén. Reméljük
                    hamarosan Önt is a ügyfeleink között tudhatjuk.
                  </p>
                </Fragment>
              )}
              <div>
                <Button label="Kapcsolat" href="/kapcsolat" />
              </div>
            </div>
            <div class="page__content__section__layout__block_2">
              <Image
                src="assets/images/telephely_2_w400.webp"
                responsive={true}
                lazy={true}
                alt={`A ${Constant.title.short} paksi telephelye`}
              />
            </div>
          </div>
        </div>
        <div class={`page__content__section ${style.section_turak}`}>
          <div class="page__content__section__layout">
            <div class="page__content__section__layout__block_2">
              <Image src="assets/images/van_3_w400.webp" responsive={true} lazy={true} alt={'Szállítójármű fuvar közben'} />
            </div>
            <div class="page__content__section__layout__block_2">
              <h1>Túrák</h1>
              <p>
                Heti rendszerességű túrajárataink paksi telephelyünkről indulnak hétfőtől péntekig, elsősorban Tolna vármegyével határos
                vármegyék szinte összes településére.
              </p>
              <p>
                Cégünk az Eurogasztro cégcsoport tagja, így partnercégeinkkel az ország teljes területén biztosított a lefedettség. Kérjen
                egyedi ajánlatot elérhetőségeink egyikén.
              </p>
              <Button label="Részletek" inverse={true} href="/turak" />
            </div>
          </div>
        </div>
        <div class={`page__content__section ${style.section_termekek}`}>
          <div class="page__content__section__layout">
            <div class="page__content__section__layout__block_2">
              <h1 class="as-start">Termékek - Aktuális ajánlataink</h1>
              <p>
                Cégünk azon dolgozik, hogy a legjobb minőségű termékeket a legjobb áron, gyorsan és pontosan a felhasználóhoz juttassa.
                Raktáron lévő termékek esetén még aznap a kezében lehet a kívánt áru.
              </p>
              <p>
                Mennyiségi kedvezmények, egyedi ajánlatok, színvonalas kiszolgálás, <span class="bold">Kajári</span>.
              </p>
              <div class="fx-r gap-r pi-center-center" style="align-self: center">
                <Button label="Termékek" href="/termekek" />
                <Button label="Ajánlataink" href="/termekek/aktualis-ajanlataink" />
              </div>
            </div>
            <div class="page__content__section__layout__block_2">
              <Image
                src="assets/images/termekek_1_w400.webp"
                responsive={true}
                lazy={true}
                alt={`Raktárkészlet a ${Constant.title.short} paksi telephelyén`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kezdooldal;
