import axios from 'axios';
import { isPrerendering } from 'services/services';

export type TermekcsoportDTO = {
  id: number;
  nev: string;
  urlPart: string;
};

export type TermekDTO = {
  id: number;
  nev: string;
  termekcsoportId: number;
  ar?: string;
  kep?: string;
};

export type QueryResult<T> = {
  totalCount: number;
  items: T[];
};

export class ApiService {
  public readonly originUrl: string;
  public readonly apiUrl: string;
  public readonly imgURL: string;
  private TermekcsoportList?: TermekcsoportDTO[];

  constructor() {
    this.originUrl = !isPrerendering() ? window.location.origin ?? '' : '';
    this.apiUrl = `${this.originUrl.replace(':8080', ':5000')}/api`;
    this.imgURL = `${this.originUrl.replace(':8080', ':5000')}/img`;
  }

  private getApiUrl(suffix: string): string {
    if (!suffix.startsWith('/')) {
      suffix = `/${suffix}`;
    }
    return `${this.apiUrl}${suffix}`;
  }

  public async getTermekcsoportList(): Promise<TermekcsoportDTO[]> {
    if (isPrerendering()) {
      return Promise.resolve([]);
    }

    if (!this.TermekcsoportList) {
      const items = await axios.get(this.getApiUrl('termekcsoport')).then((res) => {
        return (res.data || []) as TermekcsoportDTO[];
      });

      this.TermekcsoportList = items;
    }

    return this.TermekcsoportList;
  }

  public async getTermekList(args: { termekcsoportId: number; page: number; pageSize: number }): Promise<QueryResult<TermekDTO>> {
    if (isPrerendering()) {
      return Promise.resolve({ totalCount: 0, items: [] });
    }

    const items = await axios
      .get(this.getApiUrl('termek'), {
        params: {
          termekcsoportId: args.termekcsoportId,
          page: args.page,
          pageSize: args.pageSize,
        },
      })
      .then((res) => {
        return (res.data || []) as QueryResult<TermekDTO>;
      });

    return items;
  }

  public getImageLink(imagePath?: string): string | undefined {
    if (!imagePath) {
      return undefined;
    }
    return `${this.imgURL}/${encodeURIComponent(imagePath)}.webp`;
  }
}

export const ApiServiceInstance = new ApiService();
