import { Signal, signal } from '@preact/signals';

const breakpoints = {
  xs: ['0px', '599px'],
  sm: ['600px', '959.9px'],
  md: ['960px', '1279.9px'],
  lg: ['1280px', '1919.9px'],
  xl: ['1920px', '4999.9px'],
};

const overlapping_gt = {
  gt_xs: '600px',
  gt_sm: '960px',
  gt_md: '1280px',
  gt_lg: '1920px',
};

const overlapping_lt = {
  lt_sm: '599.9px',
  lt_md: '959.9px',
  lt_lg: '1279.9px',
  lt_xl: '1919.9px',
};

class BreakpointInfo {
  public isMatch(match: keyof typeof breakpoints | keyof typeof overlapping_gt | keyof typeof overlapping_lt): boolean {
    let query = '';

    if (Object.hasOwn(breakpoints, match)) {
      query = `(min-width: ${breakpoints[match][0]}) and (max-width: ${breakpoints[match][1]})`;
    } else if (Object.hasOwn(overlapping_gt, match)) {
      query = `(min-width: ${overlapping_gt[match]})`;
    } else if (Object.hasOwn(overlapping_lt, match)) {
      query = `(max-width: ${overlapping_lt[match]})`;
    } else {
      return false;
    }

    return globalThis?.matchMedia ? globalThis.matchMedia(query).matches : false;
  }
}

export function createBreakpointObserverSignal(): Signal<BreakpointInfo> {
  const s = signal(new BreakpointInfo());

  if (globalThis?.addEventListener) {
    globalThis.addEventListener('resize', () => {
      s.value = new BreakpointInfo();
    });
  }

  return s;
}
