import { h } from 'preact';
import style from './LayoutSelector.scss';

const LayoutSelector = (props: { selected: 1 | 2 | 3; onSelect: (selected: 1 | 2 | 3) => void }) => {
  return (
    <ul class={style.list}>
      <li class={`hide-lt-md ${props.selected === 3 ? style.active : null}`} onClick={(e) => props.onSelect(3)}>
        <img src="assets/icon/column_3_black.svg" />
      </li>
      <li class={props.selected === 2 ? style.active : null} onClick={(e) => props.onSelect(2)}>
        <img src="assets/icon/column_2_black.svg" />
      </li>
      <li class={props.selected === 1 ? style.active : null} onClick={(e) => props.onSelect(1)}>
        <img src="assets/icon/column_1_black.svg" />
      </li>
    </ul>
  );
};

export default LayoutSelector;
